import React, { useEffect, useState } from 'react';
import Card from '@Utils/Card';
import styles from './FunnelView.module.less';
import FunnelDatePicker from './FunnelDatePicker';
import FunnelCompletionTime from './FunnelCompletionTime';
import FunnelExecutionButtons from './FunnelExecutionButtons';
import { Tooltip } from 'antd';
import { ArrowDownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { numberWithCommas } from '@Utils/RenderUtils';
import { viewDuration } from '@Utils/DateUtils';
import { useParams } from 'react-router-dom';

const FunnelView = ({
  viewFunnel,
  executing,
  stopping,
  executionResult,
  executionId,
  executeFunnel,
  executionParams,
  viewFunnelExecution,
  fetching,
  content,
  stopFunnelExecution,
  updateFunnelExecutionParameters,
}) => {
  const { id } = useParams();
  const [completionTimeError, setCompletionTimeError] = useState(false);
  useEffect(() => {
    if (id) viewFunnel(id);
  }, [id]);

  useEffect(() => {
    const condition =
      executionResult.status === 'COMPLETED' ||
      executionResult.status === 'STOPPED';

    const interval = setInterval(() => {
      if (executionId && !condition) {
        viewFunnelExecution(executionId);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [executing, executionId, executionResult.status]);

  return (
    <div>
      <Card loading={fetching}>
        <>
          {content.steps.length ? (
            <>
              <div className={styles.execution}>
                <div className={styles.executionParams}>
                  <FunnelCompletionTime
                    executing={executing}
                    executionParams={executionParams}
                    updateFunnelExecutionParameters={
                      updateFunnelExecutionParameters
                    }
                    setCompletionTimeError={setCompletionTimeError}
                    completionTimeError={completionTimeError}
                  />
                  <FunnelDatePicker
                    executing={executing}
                    executionParams={executionParams}
                    updateFunnelExecutionParameters={
                      updateFunnelExecutionParameters
                    }
                  />
                </div>
                <div>
                  <FunnelExecutionButtons
                    completionTimeError={completionTimeError}
                    executing={executing}
                    stopping={stopping}
                    executionId={executionId}
                    executionParams={executionParams}
                    executeFunnel={executeFunnel}
                    stopFunnelExecution={stopFunnelExecution}
                  />
                </div>
              </div>
              <div className={styles.header}>
                <div className={styles.headerConversion}>
                  Conversion{' '}
                  <Tooltip title="The overall conversion rate of the funnel for the selected time period and completion time.">
                    <QuestionCircleOutlined />
                  </Tooltip>{' '}
                  <span className={styles.headerValue}>
                    {calculatePercentage(
                      executionResult.steps?.reduce(
                        (prev, current) =>
                          prev?.number > current?.number ? prev : current,
                        undefined
                      )?.usersCount || 0,
                      executionResult.totalUsersCount || 1
                    ) || 0}
                    %
                  </span>
                </div>
                <div className={styles.headerConversionTime}>
                  Average Time to Convert{' '}
                  <Tooltip title="The average time taken by a user to exit the funnel i.e. travel from step 1 to the last step.">
                    <QuestionCircleOutlined />
                  </Tooltip>{' '}
                  <span className={styles.headerValue}>
                    {viewDuration(
                      executionResult.averageConversionTimeSeconds / 60
                    ) || 0}
                  </span>
                </div>
                <div className={styles.headerRemainingTime}>
                  Remaining Time{' '}
                  <span className={styles.headerValue}>
                    {viewDuration(executionResult.remainingTimeSeconds / 60) ||
                      0}
                  </span>
                </div>
                <div></div>
              </div>
              <div className={styles.result}>
                {Array.from(
                  { length: content.steps.length },
                  (_, index) => index + 1
                ).map((stepNumber) => (
                  <>
                    {stepNumber !== 1 ? (
                      <div className={styles.resultTransition}>
                        <div className={styles.resultTransitionPercentage}>
                          <span>
                            {calculatePercentage(
                              executionResult.steps.find(
                                (step) => step.number === stepNumber
                              )?.usersCount || 0,
                              executionResult.steps.find(
                                (step) => step.number === stepNumber - 1
                              )?.usersCount || 1
                            ) || 0}
                            %
                          </span>
                        </div>
                        <ArrowDownOutlined style={{ margin: '0 1em' }} />
                        <div className={styles.resultTransitionTime}>
                          <span>
                            {viewDuration(
                              executionResult.transitions.find(
                                (transition) => transition.to === stepNumber
                              )?.averageTimeSeconds / 60
                            ) || 0}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className={styles.resultStep}>
                      <div className={styles.resultStepName}>
                        <span className={styles.resultStepNumber}>
                          {stepNumber}
                        </span>
                        {content.steps[stepNumber - 1].event.name}
                      </div>
                      <div className={styles.resultStepProgress}>
                        <span
                          style={{
                            background: `rgb(29,136,246, ${
                              1 - (stepNumber - 1) / content.steps.length
                            })`,
                            width: `${
                              calculatePercentage(
                                executionResult.steps.find(
                                  (step) => step.number === stepNumber
                                )?.usersCount || 0,
                                executionResult.totalUsersCount || 1
                              ) || 0
                            }%`,
                            transition: '1.5s',
                          }}
                        ></span>
                      </div>
                      <div className={styles.resultStepInfo}>
                        <span className={styles.resultStepPercentage}>
                          {calculatePercentage(
                            executionResult.steps.find(
                              (step) => step.number === stepNumber
                            )?.usersCount || 0,
                            executionResult.totalUsersCount || 1
                          ) || 0}
                          %
                        </span>
                        <span className={styles.resultStepUsers}>
                          {numberWithCommas(
                            executionResult.steps.find(
                              (step) => step.number === stepNumber
                            )?.usersCount
                          ) || 0}{' '}
                          users
                        </span>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          ) : (
            <div
              style={{
                color: '#3d404e',
                textAlign: 'center',
                fontSize: 18,
                fontWeight: '500',
                padding: 40,
              }}
            >
              No Data Available
            </div>
          )}
        </>
      </Card>
    </div>
  );

  function calculatePercentage(part, whole) {
    return ((part / whole) * 100).toFixed(2);
  }
};

export default FunnelView;
