import React from 'react';
import { Link } from 'react-router-dom';
import PageBase from '../../../../component/layout/PageBase';
import { Button, DatePicker, Space, Spin, Table } from 'antd';
import { viewDateTime } from '../../../../utils/DateUtils';
import {
  AuthorityProvider,
  hasAnyAuthority,
} from '../../../../utils/AuthorityProvider';
import { viewBoolean } from '../../../../utils/BooleanUtils';
import { getSegmentId } from '../../../../utils/AuthorityProvider';
import { InfoCircleOutlined } from '@ant-design/icons';
import { isEmpty, isNil } from 'lodash';
import { numberWithCommas, percentage } from '../../../../utils/RenderUtils';
import moment from 'moment';
import chart from '../../../../theme/images/icons/chart.png';
import chartBar from '../../../../theme/images/icons/chart-bar.png';
import chartTable from '../../../../theme/images/icons/chart-table.png';
import { Line } from '@ant-design/charts';
import { LoadingOutlined } from '@ant-design/icons';
import OverviewBox from '../../../../component/form/OverviewBox';
import Card from '@Utils/Card';

const styles = {
  loadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};
class ProductSegmentOverviewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      segmentSizeReportDuration: this.updateState(),
    };
  }
  updateState() {
    const createdDate = moment(this.props.currentProductSegment.createdDate);
    const sevenDaysAgo = moment().subtract(7, 'days');
    return {
      fromDate: moment.max(createdDate, sevenDaysAgo).format('YYYY-MM-DD'),

      toDate: moment().format('YYYY-MM-DD'),
    };
  }
  componentDidMount() {
    const segmentId = getSegmentId(this.props.pathname); //this.props.match.params.id
    this.props.productSegmentViewFetch(segmentId);
    this.props.productSegmentReportViewFetch(segmentId);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.currentProductSegment.createdDate !==
      this.props.currentProductSegment.createdDate
    ) {
      this.setState({
        segmentSizeReportDuration: this.updateState(),
      });
      this.segmentSizeReportFetch(this.updateState());
    }
  }

  componentWillUnmount() {
    this.props.productSegmentChangeCurrent({});
  }

  segmentSizeReportFetch = (duration) => {
    this.props.productSegmentSizeReport({
      ...duration,
      segmentId: getSegmentId(this.props.pathname),
    });
  };

  getNavigation() {
    return [
      { name: 'Segment List', path: '../list' },
      { name: 'View Segment', path: '' },
    ];
  }

  disabledDate = (current) => {
    return current && current > moment().startOf('day');
  };

  handleChangeDateRange = (dates) => {
    const segmentSizeReportDuration = {
      fromDate: dates[0].format('YYYY-MM-DD'),
      toDate: dates[1].format('YYYY-MM-DD'),
    };
    this.setState({ segmentSizeReportDuration }, () => {
      this.segmentSizeReportFetch(segmentSizeReportDuration);
    });
  };

  renderNumber = (number) => {
    if (isNil(number)) {
      return 0;
    }
    return numberWithCommas(number);
  };

  renderKnownUsers = () => {
    if (
      this.props.currentReport &&
      !isNil(this.props.currentReport.knownUsersCount)
    ) {
      return this.renderNumber(this.props.currentReport.knownUsersCount);
    }
    return <div style={{ height: '80px' }}></div>;
  };

  renderUnknownUsers = () => {
    if (
      this.props.currentReport &&
      !isNil(this.props.currentReport.unknownUsersCount)
    ) {
      return this.renderNumber(this.props.currentReport.unknownUsersCount);
    }
    return <div style={{ height: '80px' }}></div>;
  };

  renderKnownUsersPercentage = () => {
    if (this.props.currentReport) {
      return percentage(
        this.props.currentReport.knownUsersCount,
        Number(this.props.currentReport.knownUsersCount) +
          Number(this.props.currentReport.unknownUsersCount)
      );
    }
    return <div style={{ height: '10px' }}></div>;
  };

  renderUnknownUsersPercentage = () => {
    if (this.props.currentReport) {
      return percentage(
        this.props.currentReport.unknownUsersCount,
        Number(this.props.currentReport.knownUsersCount) +
          Number(this.props.currentReport.unknownUsersCount)
      );
    }
    return <div style={{ height: '80px' }}></div>;
  };

  renderReport = () => {
    const data = [];
    this.props.segmentSizeReport.forEach((item) => {
      const date = moment(item.reportDate, 'YYYY-MM-DD').format('DD MMM');
      data.push({
        date,
        value: item.segmentSize,
      });
    });
    const config = {
      data,
      height: 400,
      xField: 'date',
      yField: 'value',
      padding: 'auto',
      color: '#FD578D',
      lineStyle: function lineStyle() {
        return {
          lineDash: [4, 4],
          opacity: 1,
        };
      },
      yAxis: {
        label: {
          formatter: function formatter(v) {
            return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
              return ''.concat(s, ',');
            });
          },
        },
      },
      point: {
        size: 5,
        shape: 'circle',
      },
      legend: {
        position: 'right',
        verticalAlign: 'middle',
      },
      meta: {
        value: {
          formatter: (v) => {
            return this.renderNumber(v);
          },
        },
      },
    };

    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    return (
      <Card
        styles={{
          paddingBottom: 5,
          marginTop: 24,
        }}
      >
        {this.props.loadingOverviewList ? (
          <div
            style={{
              ...styles.loadingSpinStyle,
              paddingBottom: 30,
              paddingTop: 30,
            }}
          >
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '12px',
                paddingLeft: '20px',
                borderBottom: 'solid 2px #f4f9ff',
              }}
            >
              <div
                style={{ color: '#1c1c1c', fontSize: 16, fontWeight: '600' }}
              >
                Segment Size
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <DatePicker.RangePicker
                  allowClear={false}
                  disabledDate={(date) => date.isAfter(moment())}
                  value={[
                    moment(
                      this.state.segmentSizeReportDuration.fromDate,
                      'YYYY-MM-DD'
                    ),
                    moment(
                      this.state.segmentSizeReportDuration.toDate,
                      'YYYY-MM-DD'
                    ),
                  ]}
                  onChange={(dates) => this.handleChangeDateRange(dates)}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginRight: 10,
                    marginBottom: 5,
                  }}
                >
                  <div style={{ cursor: 'pointer', marginLeft: 25 }}>
                    <img src={chart} style={{ width: 16, height: 'auto' }} />
                  </div>
                  <div
                    style={{
                      cursor: 'pointer',
                      marginRight: 15,
                      marginLeft: 15,
                    }}
                  >
                    <img src={chartBar} style={{ width: 16, height: 'auto' }} />
                  </div>
                  <div style={{ cursor: 'pointer' }}>
                    <img
                      src={chartTable}
                      style={{ width: 16, height: 'auto' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {isEmpty(data) ? (
              <div style={{ padding: '4px 24px' }}>
                {` We're gathering data for this segment, and the chart will be
              available tomorrow.`}
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: 'white',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    writingMode: 'vertical-rl',
                    textOrientation: 'mixed',
                    textAlign: 'center',
                    paddingLeft: 14,
                  }}
                >
                  USERS
                </div>
                <Line
                  {...config}
                  style={{
                    flex: 1,
                    paddingRight: 50,
                    paddingLeft: 20,
                    paddingBottom: 20,
                    paddingTop: 20,
                    width: window.innerWidth - 300,
                  }}
                />
              </div>
            )}
          </div>
        )}
      </Card>
    );
  };

  renderTotalUsers = () => {
    if (
      this.props.currentReport &&
      (!isNil(this.props.currentReport.knownUsersCount) ||
        !isNil(this.props.currentReport.unknownUsersCount))
    ) {
      return this.renderNumber(
        this.props.currentReport.knownUsersCount +
          this.props.currentReport.unknownUsersCount
      );
    }
    return <div style={{ height: '80px' }}></div>;
  };

  renderOverview = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <OverviewBox
          activityLoading={this.props.loadingOverviewList}
          usersStatus="TOTAL USERS"
          usersNumbers={this.renderTotalUsers()}
          segmentUsers={true}
        ></OverviewBox>
        <OverviewBox
          usersNumbers={this.renderKnownUsers()}
          usersStatus="KNOWN USERS"
          activityLoading={this.props.loadingOverviewList}
          knownUsers={true}
          knownUsersnumbers={this.renderKnownUsersPercentage() + '%'}
          segmentUsers={true}
        ></OverviewBox>
        <OverviewBox
          activityLoading={this.props.loadingOverviewList}
          usersStatus="UNKNOWN USERS"
          usersNumbers={this.renderUnknownUsers()}
          segmentUsers={true}
        ></OverviewBox>
      </div>
    );
  };

  reachabilityCount = (item) => {
    let result = 0;
    if (this.props.currentReport && this.props.currentReport[item]) {
      result = this.props.currentReport[item];
    }
    return result;
  };

  renderChannels = () => {
    const channels = {
      push: 'Push',
      sms: 'SMS',
      webPush: 'Web Push',
      email: 'Email',
      inApp: 'In App',
      onSite: 'On Site',
      overall: 'Overall',
    };
    const data = [
      {
        key: 'push',
        name: channels['push'],
        reachability: percentage(
          this.reachabilityCount('pushReachableUsersCount'),
          Number(
            this.reachabilityCount('knownUsersCount') +
              Number(this.reachabilityCount('unknownUsersCount'))
          )
        ),
        reachabilityCount: this.renderNumber(
          this.reachabilityCount('pushReachableUsersCount')
        ),
      },
      {
        key: 'sms',
        name: channels['sms'],
        reachability: percentage(
          this.reachabilityCount('phoneReachableUsersCount'),
          Number(
            this.reachabilityCount('knownUsersCount') +
              Number(this.reachabilityCount('unknownUsersCount'))
          )
        ),
        reachabilityCount: this.renderNumber(
          this.reachabilityCount('phoneReachableUsersCount')
        ),
      },
      {
        key: 'webPush',
        name: channels['webPush'],
        reachability: percentage(
          this.reachabilityCount('webPushReachableUsersCount'),
          Number(
            this.reachabilityCount('knownUsersCount') +
              Number(this.reachabilityCount('unknownUsersCount'))
          )
        ),
        reachabilityCount: this.renderNumber(
          this.reachabilityCount('webPushReachableUsersCount')
        ),
      },
      {
        key: 'email',
        name: channels['email'],
        reachability: percentage(
          this.reachabilityCount('emailReachableUsersCount'),
          Number(
            this.reachabilityCount('knownUsersCount') +
              Number(this.reachabilityCount('unknownUsersCount'))
          )
        ),
        reachabilityCount: this.renderNumber(
          this.reachabilityCount('emailReachableUsersCount')
        ),
      },
      {
        key: 'onSite',
        name: channels['onSite'],
        reachability: percentage(
          this.reachabilityCount('onSiteReachableUsersCount'),
          Number(
            this.reachabilityCount('knownUsersCount') +
              Number(this.reachabilityCount('unknownUsersCount'))
          )
        ),
        reachabilityCount: this.renderNumber(
          this.reachabilityCount('onSiteReachableUsersCount')
        ),
      },
      {
        key: 'inApp',
        name: channels['inApp'],
        reachability: percentage(
          this.reachabilityCount('inAppReachableUsersCount'),
          Number(
            this.reachabilityCount('knownUsersCount') +
              Number(this.reachabilityCount('unknownUsersCount'))
          )
        ),
        reachabilityCount: this.renderNumber(
          this.reachabilityCount('inAppReachableUsersCount')
        ),
      },
      {
        key: 'overall',
        name: channels['overall'],
        reachability: percentage(
          this.reachabilityCount('knownUsersCount') +
            this.reachabilityCount('unknownUsersCount') -
            this.reachabilityCount('unreachableUsersCount'),
          Number(
            this.reachabilityCount('knownUsersCount') +
              Number(this.reachabilityCount('unknownUsersCount'))
          )
        ),
        reachabilityCount: this.renderNumber(
          this.reachabilityCount('knownUsersCount') +
            this.reachabilityCount('unknownUsersCount') -
            this.reachabilityCount('unreachableUsersCount')
        ),
      },
    ];
    const columns = [
      {
        title: '',
        dataIndex: 'name',
        key: 'name',
        render: (text) => (
          <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
        ),
      },
      {
        title: 'reachability',
        dataIndex: 'reachability',
        key: 'reachability',
        render: (text) => (
          <span style={{ fontSize: 14, fontWeight: '600' }}>{text + ' %'}</span>
        ),
      },
      {
        title: 'reachability (#)',
        dataIndex: 'reachabilityCount',
        key: 'reachabilityCount',
        render: (text) => (
          <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
        ),
      },
    ];

    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    return (
      <Card
        styles={{
          marginTop: 24,
        }}
      >
        {this.props.loadingOverviewList ? (
          <div
            style={{
              ...styles.loadingSpinStyle,
              paddingBottom: 30,
              paddingTop: 30,
            }}
          >
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <Card>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '12px',
                paddingBottom: 0,
                paddingLeft: '20px',
              }}
            >
              <div
                style={{ color: '#000000', fontSize: 16, fontWeight: '600' }}
              >
                Channel Reachability
                <span style={{ paddingLeft: 10 }}>
                  {false && (
                    <InfoCircleOutlined
                      style={{ color: '#8C8C8C', fontSize: 12 }}
                    />
                  )}
                </span>
              </div>
            </div>
            <Table
              className={'custom-table'}
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </Card>
        )}
      </Card>
    );
  };

  renderOld = () => {
    return (
      <PageBase title="View Segment" navigation={this.getNavigation()}>
        <dl>
          <dt>Created Date</dt>
          <dd>{viewDateTime(this.props.currentProductSegment.createdDate)}</dd>
          <dt>Modified Date</dt>
          <dd>{viewDateTime(this.props.currentProductSegment.modifiedDate)}</dd>
          <dt>Created By</dt>
          <dd>
            {this.props.currentProductSegment.createdBy
              ? this.props.currentProductSegment.createdBy.presentation
              : ''}
          </dd>
          <dt>Modified By</dt>
          <dd>
            {this.props.currentProductSegment.modifiedBy
              ? this.props.currentProductSegment.modifiedBy.presentation
              : ''}
          </dd>
          <dt>Name</dt>
          <dd>{this.props.currentProductSegment.name}</dd>
          <dt>Enabled</dt>
          <dd>{viewBoolean(this.props.currentProductSegment.enabled)}</dd>
          <dt>Test</dt>
          <dd>{viewBoolean(this.props.currentProductSegment.test)}</dd>
        </dl>
        <Space>
          {hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_UPDATE) && (
            <Link to={`../update/${this.props.match.params.id}`}>
              <Button>Update</Button>
            </Link>
          )}
          <Link to="../list">
            <Button>Back</Button>
          </Link>
        </Space>
      </PageBase>
    );
  };

  render() {
    return (
      <div>
        {this.renderOverview()}
        {this.renderReport()}
        {this.renderChannels()}
      </div>
    );
  }
}

export default ProductSegmentOverviewComponent;
