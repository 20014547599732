import React, { useLayoutEffect } from 'react';
import viewOnly from '@Theme/icons/warning.svg';
import limited from '@Theme/icons/view-only.svg';
import sandbox from '@Theme/icons/sandbox.svg';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import './style.less';

const userActiveProduct = (currentUser) => {
  if (currentUser?.lastProduct) {
    return currentUser?.products?.find((p) => p.id === currentUser.lastProduct);
  }

  return currentUser?.products && currentUser?.products[0];
};

const data = {
  RESTRICTED: {
    className: 'box-view-only',
    title: 'View Only',
    description:
      'Your access to this project has been changed to view-only due to non-payment of fees',
    icon: viewOnly,
  },
  PENALIZED: {
    className: 'box-limited',
    title: 'Limited Project',
    description:
      'Your access to this project has been limited due to non-payment.',
    icon: limited,
  },
  DISABLED: {
    className: 'box-limited',
    title: 'Blocked Project',
    description:
      'Your project has been blocked after 28 days due to non-payment.',
    icon: limited,
  },
};

const useUpdateBannerStackSize = ({ product, setBannerStackSize }) => {
  useLayoutEffect(() => {
    let count = 0;

    if (product?.demo) count += 1;

    if (data[product?.restrictionConstraint ?? 'CLEARNACE']) count += 1;

    setBannerStackSize(count);
  }, [product]);
};

const Sandbox = ({ product }) => {
  if (!product?.demo) return null;

  return (
    <div className={`box box-sandbox`}>
      <div className="banner">
        <img src={sandbox} />
        <span>Sandbox Project</span>
      </div>
      <div className="banner-content">
        <span className="banner-description">
          This project is currently in sandbox mode, meaning no communications
          will be transmitted to end users.
        </span>
      </div>
    </div>
  );
};

const Restricted = ({ product }) => {
  const metadata = data[product?.restrictionConstraint ?? 'CLEARNACE'];

  if (!metadata) return null;

  return (
    <div
      className={`box ${metadata.className}`}
      style={{ borderTop: product?.demo ? 'none' : undefined }}
    >
      <div className="banner">
        <img src={metadata.icon} />
        <span>{metadata.title}</span>
      </div>
      <div className="banner-content">
        <span className="banner-description">{metadata.description}</span>
        <Link to={`/product/${product.id}/billing/invoice`}>
          <span className="pay-now">Pay now</span>
          <RightOutlined className="pay-now-arrow" />
        </Link>
      </div>
    </div>
  );
};

const StickyBanner = ({ currentUser, setBannerStackSize, loading }) => {
  const product = userActiveProduct(currentUser);

  useUpdateBannerStackSize({ product, setBannerStackSize });

  if (loading || !product) return null;

  return (
    <div className="banners-container">
      <Sandbox product={product} />
      <Restricted product={product} />
    </div>
  );
};

export default StickyBanner;
