import { Button } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';

const FunnelExecutionButtons = ({
  completionTimeError,
  executing,
  stopping,
  executionId,
  executionParams,
  executeFunnel,
  stopFunnelExecution,
}) => {
  const { id } = useParams();

  return (
    <div style={{ padding: 12, display: 'flex', alignItems: 'center', gap: 8 }}>
      <Button
        type="primary"
        htmlType="submit"
        disabled={executing || stopping || completionTimeError}
        onClick={() => {
          executionParams.funnelId = id;
          executeFunnel(executionParams);
        }}
      >
        Execute
      </Button>
      <Button
        type="ghost"
        htmlType="submit"
        onClick={() => {
          stopFunnelExecution(executionId);
        }}
        disabled={!executing || !executionId}
      >
        STOP
      </Button>
    </div>
  );
};

export default FunnelExecutionButtons;
